import React from "react"
import Layout from "../components/layout"
import styled from "@emotion/styled"
import { Global, css } from "@emotion/react"
import {color, spacing, screen} from "../constants"
import { Link } from "gatsby"

import useToggle from "../components/useToggle"
import Footer from "../components/footer"

export default function Saison2021() {
  const [dateOpen, toggleDate] = useToggle();
  const [ticketOpen, toggleTicket] = useToggle();
  
  
  return (
    <Layout title="Saison 2021">
      <main css={css`
        background-color: ${color.light};
        flex: 1 1 980px;
        
        padding: ${spacing.xs};
        margin: 0;

        strong {
          color: black;
        }

        @media (min-width: ${screen.xsPhone}) {
          padding: ${spacing.medium};
          margin: 0 ${spacing.small};
        }
        @media (min-width: ${screen.tablet}) {
          padding: calc(0.75*${spacing.big}) ${spacing.big};
          margin: 0 ${spacing.medium};
        }
      `}>
        <h1>Saisonprogramm 2023/2024</h1>


<p>
  <br/>
  <hr />
</p>

<p><a css={css`text-decoration: none; color: ${color.secondaryDark};`} href="/GML_Kreuzlingen_Jahresprogramm_2023_24-1.pdf">Unser Jahresprogramm Saison 2023/24 als PDF Download</a>&nbsp;</p>

<p>
  <hr />
  <br/>
</p>

<p>Sonntag, 3. September 2023, 19 Uhr; Kirche St. Ulrich Kreuzlingen</p>
<p>Sonderkonzert | &bdquo;Todtenfeld&ldquo; - Oratorium des Kreuzlinger Komponisten Ulrich Gasser</p>
<p>Unter der Leitung von Lukas Grimm bringt das Stuttgarter Kammerensemble ein brandneues Werk des Kreuzlinger Komponisten Ulrich Gasser zur Schweizerischen Erstauff&uuml;hrung. Ausgehend von der barocken Kanzel im M&uuml;nster Zwiefalten mit der ber&uuml;hmten Vision des Ezechiel von der Auferstehung der Toten, greift das Oratorium &bdquo;Todtenfeldt&ldquo; aktuelle Fragen auf nach der Erweckung aus ausweglos scheinender Situation zu neuer Lebendigkeit. Die international bekannten Gesangssolisten Johanna Zimmer (Sopran) und Georg G&auml;dker (Bariton) &uuml;bernehmen Schl&uuml;sselrollen in der Erz&auml;hlung dieser Geschichte.</p>
<p>Tickets: CHF 35 / &euro; 35 | Sch&uuml;ler*innen und Studierende CHF 5 / &euro;5</p>

<p>
  <br/>
  <hr />
  <br/>
</p>

<p>Freitag, 29. September 2023; Kult-X Kreuzlingen<br/>18.15 Uhr: GML-Generalversammlung&nbsp;</p>
<p>19.30 Uhr: Extrakonzert nur f&uuml;r Abonnent*innen und Mitglieder | Akkordeonkl&auml;nge mit Alexandre Bytchkov</p>
<p>Ein besonderes H&ouml;rerlebnis verspricht diese Extraveranstaltung mit dem russischen Akkordeonvirtuosen Alexandre Bytchkov. Der mittlerweile in Deutschland beheimatete bekannte Virtuose und Preistr&auml;ger nationaler und internationaler Wettbewerbe pr&auml;sentiert die breite Palette der M&ouml;glichkeiten des Akkordeons, welches in unseren Breiten oft nur aus der Volksmusik bekannt ist. Nicht so in anderen L&auml;ndern: Alexandre Bytchkov entf&uuml;hrt die Zuh&ouml;renden in unterschiedlichste Musikwelten und entfacht auf dem Akkordeon ein wahres Feuerwerk mit hohem k&uuml;nstlerischem Anspruch.</p>
<p>Eintritt f&uuml;r Mitglieder und Abonnent*innen frei</p>

<p>
  <br/>
  <hr />
  <br/>
</p>

<p>Sonntag, 12. November 2023, 17 Uhr; Evangelische Kirche T&auml;gerwilen</p>
<p>Abonnementskonzert | Chorkonzert - Winterthurer Vokalensemble</p>
<p>&bdquo;De Miragres et Misteres&ldquo; - unter der Leitung des Dirigenten Beat Merz singt das weit herum gesch&auml;tzte Winterthurer Vokalensemble von Wundern und Geheimnissen. Musik aus vier Jahrhunderten zwischen Sp&auml;tmittelalter und Fr&uuml;hbarock entf&uuml;hrt uns in eine andere Welt und erz&auml;hlt von Wundertaten der Jungfrau Maria und von tiefen Geheimnissen, die sie umranken.</p>
<p>Tickets: CHF 35 / &euro; 35 | Sch&uuml;ler*innen und Studierende CHF 5 / &euro;5</p>

<p>
  <br/>
  <hr />
  <br/>
</p>

<p>Freitag, 8. Dezember 2023, 19.30 Uhr; Kult-X Kreuzlingen<br/>2. Abonnementskonzert | Preistr&auml;gerkonzert Orpheus Chamber Music Competition</p>
<p>Der Orpheus-Wettbewerb ist mit seiner fast 50-j&auml;hrigen Geschichte ein wichtige Instanz der Schweizer Nachwuchsf&ouml;rderung. Dieses Jahr findet er im November 2023 in Luzern vor einer hochkar&auml;tigen Jury (Jurypr&auml;sidium: Heinz Holliger) statt. Teilnehmen k&ouml;nnen Ensembles von Trio bis Nonett, deren Mitwirkende Schweizer*innen sind oder an einer Schweizer Musikhochschule studieren. Wir sind gespannt, welches Ensemble ausgew&auml;hlt wird und freuen uns auf die jungen Preistr&auml;ger*innen!</p>
<p>Tickets: CHF 25 / &euro; 25 | Sch&uuml;ler*innen und Studierende CHF 5 / &euro;5</p>

<p>
  <br/>
  <hr />
  <br/>
</p>

<p>Sonntag, 14. Januar 2024, 17 Uhr; Das Tr&ouml;sch Kreuzlingen</p>
<p>Neujahrskonzert | Cello-Comedy mit &nbsp;dem Duo Calva: &bdquo;Im Himmel&ldquo;</p>
<p>Alain Schudel &amp; Daniel Schaerer, Cello</p>
<p>Regie: Dominique Müller - Dramaturgie: Charles Lewinsky</p>
<p>Nachdem das Duo Calva in ihrem letzten St&uuml;ck &bdquo;Zwei Celli f&uuml;r ein Halleluja&ldquo; einen tragischen Unfall beim Sturz aus der Zirkuskuppel erlitten haben, melden sie sich aus ihrem neuen Wohnort &bdquo;im Himmel&ldquo; zur&uuml;ck. Mit je zwei Fl&uuml;geln auf dem R&uuml;cken hat sich ihre musikalische Spannweite sehr erweitert, und so bieten sie ihrem auf der Erde klagend zur&uuml;ckgebliebenen Publikum auch in ihrem neuen Programm ein himmlisches Vergn&uuml;gen.</p>
<p>Eintritt frei | Kollekte</p>

<p>
  <br/>
  <hr />
  <br/>
</p>

<p>Donnerstag, 22. Februar 2024, 19.30 Uhr; Refektorium der PMS Kreuzlingen<br/>3. Abonnementskonzert | Gitarrenrezital mit Daniel Marx</p>
<p>Heute in Kreuzlingen t&auml;tig, begann die internationale Karriere von Daniel Marx mit dem Gewinn diverser Preise und Auszeichnungen in Deutschland, Griechenland, den USA, Italien und England, die ihm unter anderem die Aufnahme von zwei Alben mit den Labels GENUIN classics und NAXOS erm&ouml;glichten. Seine feinf&uuml;hligen Interpretationen und faszinierende Gestaltungsf&auml;higkeit wurden von der Kritik ebenso gelobt, wie das Verm&ouml;gen, die Musik an erster Stelle kommen zu lassen und menschliche Emotionen mit ihr zu verbinden. Zu uns kommt er mit einem Programm selten geh&ouml;rter romantischer Gitarrenmusik.</p>
<p>Tickets: CHF 35 / &euro; 35 | Sch&uuml;ler*innen und Studierende CHF 5 / &euro;5</p>

<p>
  <br/>
  <hr />
  <br/>
</p>

<p>Sonntag, 17. M&auml;rz 2024, 17 Uhr; Psychiatrische Klinik M&uuml;nsterlingen</p>
<p>4. Abonnementskonzert | Symphoniekonzert mit Mozart, Beethoven und Mendelssohn</p>
<p>Das Jugendorchester Thurgau hat sich in seinem mehr als 50-j&auml;hrigen Bestehen zu einem &uuml;berregional bekannten Klangk&ouml;rper entwickelt. Unter der Leitung von Gabriel Estarellas Pascual hat sich das Orchester immer mehr professionalisiert und tritt zum ersten Mal seit langer Zeit wieder in einem unserer Konzerte auf. Dieses Mal musizieren die jungen Musiker*innen ein fr&uuml;hlingshaftes Programm mit Sinfonien von Mendelssohn und Beethoven und begleiten den fabelhaften Thurgauer Solisten Gabriel Uebigau in Mozarts drittem Hornkonzert.</p>
<p>Tickets: CHF 35 / &euro; 35 | Sch&uuml;ler*innen und Studierende CHF 5 / &euro;5</p>

<p>
  <br/>
  <hr />
  <br/>
</p>

<p>Freitag, 3. Mai 2024, 19.30 Uhr; &nbsp;Campus-Aula PMS Kreuzlingen</p>
<p>5. Abonnementskonzert | Bodenseefestival mit dem Gershwin Piano Quartet</p>
<p>Die vier Pianisten des Gershwin Piano Quartet sorgen mit ihrer Mischung aus klassischer Virtuosit&auml;t und jazziger Improvisationslust, ihrer Kombination aus lyrischer Versonnenheit und unwiderstehlichem rhythmischen Drive bei Konzerten weltweit regelm&auml;ssig f&uuml;r Furore. Im Zentrum ihrer Arbeit steht immer die sehr pers&ouml;nliche Auseinandersetzung mit den gew&auml;hlten Werken, welche die &uuml;blichen Grenzen der Interpretation weit &uuml;berschreitet und von der originalgetreuen Transkription bis hin zur eigentlichen Neukomposition, zu Improvisations- und Remix-Techniken reicht. Im Rahmen des Bodenseefestivals (Thema: &bdquo;vielstimmig - einstimmig&ldquo;) kommt es zum zweiten Mal nach Kreuzlingen.</p>
<p>Tickets: CHF 35 / &euro; 35 | Sch&uuml;ler*innen und Studierende CHF 5 / &euro;5</p>

<p>
  <br/>
  <hr />
  <br/>
</p>


<p>Sonntag, 23. Juni 2024, 19.30 Uhr; Atrium der Campus-Aula PMS Kreuzlingen</p>
<p>Serenade | Don Camillo und Peppone</p>
<p>Die Geschichte vom eigensinnigen Pfarrer und vom ebenso starrk&ouml;pfigen kommunistischen B&uuml;rgermeister, meisterhaft in Szene gesetzt durch Stefan M&uuml;ller-Ruppert und ebenso meisterhaft musikalisch umrahmt vom Gitarristen Maximilian Mangold. Dieser heitere Schelmenroman von Giovanni Guareschi &uuml;ber die beiden alten Rivalen, die gerne Ihre F&auml;uste sprechen lassen in einem Dorf irgendwo in der Po-Ebene Italiens, war eines der erfolgreichsten B&uuml;cher der Nachkriegszeit. Die beiden humorvollen K&uuml;nstler kommen zum zweiten Mal zu uns und bescheren uns einen h&ouml;chst vergn&uuml;glichen Abschluss dieser Konzertsaison.</p>
<p>Eintritt frei | Kollekte</p>

<p>
  <br/>
  <hr />
  <br/>
</p>

<p>ABONNEMENT 5 KONZERTE: CHF 140</p>
<p>&Uuml;BERTRAGBARE WERTKARTE KARTE: CHF 140 (berechtigt zum Eintritt von 5 Konzerten, g&uuml;ltig 2 Jahre)&nbsp; &nbsp;&nbsp;&nbsp;<br/>VORVERKAUF jeweils ab 3 Wochen vor der Veranstaltung:</p>
<p>Papeterie Bodan Kreuzlingen, Tel. <a css={css`text-decoration: none; color: ${color.secondaryDark};`} href="tel:+41%2071%20672%2011%2011">+41 (0)71 672 11 11</a>, <a css={css`text-decoration: none; color: ${color.secondaryDark};`} href="mailto:tickets@gml-kreuzlingen.ch">tickets@gml-kreuzlingen.ch</a>&nbsp;</p>
<p><a css={css`text-decoration: none; color: ${color.secondaryDark};`} href="http://www.gml-kreuzlingen.ch/">www.gml-kreuzlingen.ch</a>&nbsp;</p>
<p>Die Konzerte der GML Kreuzlingen werden erm&ouml;glicht durch Stadt Kreuzlingen, Kulturstiftung Thurgau, Dr. Heinrich Mezger- Stiftung, TKB Jubil&auml;umsstiftung, Alice Wartemann - Stiftung und Thurgauische Kulturstiftung Ottoberg</p>



        <div css={css`margin: 4em 0 1em 0;`}>
          <Link to="/konzerte" css={css`text-decoration: none; color: ${color.secondaryDark};`}>{`>`} Vergangene Konzerte</Link><br/>
        </div>

        <Footer marginTop={false}/>
      </main>
    </Layout>
  )
}
